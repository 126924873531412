import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import './index.css'

import face_shield_icon from '../../assets/img/face_shield.png'
import hand_sewn_mask_icon from '../../assets/img/hand_sewn_mask.png'
import surgical_mask_icon from '../../assets/img/surgical_mask.png'
import gloves_icon from '../../assets/img/gloves.png'
import hand_sanitizer_icon from '../../assets/img/hand_sanitizer.png'
import AOS from 'aos'
import 'aos/dist/aos.css'

function DistributionTotals ({ items }) {
  const totals = {
    faceShields: 0,
    surgicalMasks: 0,
    handSewnMasks: 0,
    handSanitizer: 0,
    gloves: 0,
  }

  // iterate through the items list and sum each item type
  for (let i = 0; i < items.length; i++) {
    const item = items[i].data

    const faceShields = parseInt(item.Face_Shields)
    const surgicalMasks = parseInt(item.Surgical_Masks)
    const handSewnMasks = parseInt(item.Adult_Sewn_Masks)
    const handSanitizer = parseInt(item.Hand_Sanitizer)
    const gloves = parseInt(item.Gloves)

    if (!Number.isNaN(faceShields)) {
      totals.faceShields += faceShields
    }
    if (!Number.isNaN(surgicalMasks)) {
      totals.surgicalMasks += surgicalMasks
    }
    if (!Number.isNaN(handSewnMasks)) {
      totals.handSewnMasks += handSewnMasks
    }
    if (!Number.isNaN(handSanitizer)) {
      totals.handSanitizer += handSanitizer
    }
    if (!Number.isNaN(gloves)) {
      totals.gloves += gloves
    }
  }

  // format totals with commas for display
  for (const itemType in totals) {
    const total = totals[itemType]
    if (!Number.isNaN(total)) {
      totals[itemType] = Number(total).toLocaleString()
    }
  }

  return (
    <div>
      <h1 className='title pt-6' data-aos='fade-right' data-aos-duration='1000'>Delivery Breakdown</h1>
      <div className='is-flex center'>
        <div className='card delivery-breakdown-card px-5 py-4 mx-2 mb-6'>
          <img className='image-ppe' src={face_shield_icon} alt='Face Shield' />
          <p className='title'>{totals.faceShields}</p>
          <p className='subtitle is-7'>Face Shields</p>
        </div>
        <div className='card delivery-breakdown-card px-5 py-4 mx-2 mb-6'>
          <img className='image-ppe' src={surgical_mask_icon} alt='Surgical Mask' />
          <p className='title'>{totals.surgicalMasks}</p>
          <p className='subtitle is-7'>Surgical Masks</p>
        </div>
        <div className='card delivery-breakdown-card px-5 py-4 mx-2 mb-6'>
          <img className='image-ppe' src={hand_sewn_mask_icon} alt='Adult Sewn Mask' />
          <p className='title'>{totals.handSewnMasks}</p>
          <p className='subtitle is-7'>Adult Sewn Masks</p>
        </div>
        <div className='card delivery-breakdown-card px-5 py-4 mx-2 mb-6'>
          <img className='image-ppe' src={hand_sanitizer_icon} alt='Hand Sanitizer' />
          <p className='title'>{totals.handSanitizer}</p>
          <p className='subtitle is-7'>Hand Sanitizer</p>
        </div>
        <div className='card delivery-breakdown-card px-5 py-4 mx-2 mb-6'>
          <img className='image-ppe' src={gloves_icon} alt='Gloves' />
          <p className='title'>{totals.gloves}</p>
          <p className='subtitle is-7'>Gloves</p>
        </div>
      </div>
    </div>
  )
}

const StatsPageTemplate = ({ title, subtitle }) => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      DistUpdates: allAirtable(
        filter: { table: { eq: "Distribution Updates" } }
      ) {
        nodes {
          data {
            Name
            Date_Shipped
            Face_Shields
            Surgical_Masks
            Adult_Sewn_Masks
            Hand_Sanitizer
            Gloves
          }
          recordId
        }
      }
    }
    `)
    if(typeof window !== 'undefined' || typeof document !== 'undefined'){
      if (window.location.pathname.indexOf('admin') > 0) {
        AOS.init({
          disable: false,
        });
      } else AOS.init({disable: false});
    }
    return (
    <div>
      <section className='hero is-primary is-bold is-medium'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <h1 className='title' data-aos='fade-down' data-aos-duration='1000'>
                    {title}
                  </h1>
                  <h2 className='subtitle' data-aos='fade-down' data-aos-duration='1000'>
                    {subtitle}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className='container'>
        <DistributionTotals items={data.DistUpdates.nodes} />
        <iframe className="airtable-embed" src="https://airtable.com/embed/shrNlARel8vtXryjG?backgroundColor=yellow&viewControls=on" frameborder="0" onmousewheel="" width="100%" height="533"></iframe>
      </div>
    </div>
  )
}

export default StatsPageTemplate
